
import {Vue, Component} from 'vue-property-decorator';
import {ProgramPaths} from "@/enum/ProgramPaths";

@Component({})
export default class LoginCallback extends Vue {
  err:any=null;

  async mounted() {
    this.err=null
    await this.init();
  }

  async init() {
    try {
      let res = await this.$http.post('/api/v1/oauth/create-token', this.$route.query);
      this.$store.commit('setAccessToken', res.data.access_token);
      let tenantList = await this.$store.dispatch("getTenantList");
      if (tenantList.length > 0) {
        let defaultTenant = tenantList[0];
        this.$store.commit('setTenantId', defaultTenant.tenant_id);
      } else {
        throw new Error("Ürün lisansınız bulunamadı!");
      }
      //sessionStorage.setItem('tenantId', res.data.tenant_id);
      let userRes = await this.$http.get("/api/v1/ben")
      this.$store.commit('setUser', userRes);
      //this.$store.dispatch('updateTasks');
      let routingPath = localStorage.getItem('routingPath');
      //this.$router.push(ProgramPaths.ofispro);
      if (routingPath) {
        if (routingPath == "/giris")
          this.$router.push(ProgramPaths.ofispro);
        else
          this.$router.push(routingPath);
        //location.href = routingPath;
        localStorage.removeItem('routingPath');
      } else {
        this.$router.push(ProgramPaths.ofispro);
        //location.href = ProgramPaths.ofispro;
      }
    } catch (e) {
      console.error('HATA------: ',e);
      this.err=e;
    }
  }

  exit() {
    localStorage.clear();
    sessionStorage.clear();
    location.href = this.$store.state.ssoUri + "force-logout?redirect_uri=" + location.origin;
  }
}
